import styled from "styled-components";

const CookiesWrapper = styled.div`
  .btnWrapper {
    margin-right: 15px;
    @media (max-width: 767px) {
      display: flex;
      flex: 1;
      margin-right: 0px;
    }
  }
`;

export default CookiesWrapper;
