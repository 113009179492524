import Text from 'common/src/components/Text';
import PropTypes from 'prop-types';
import React from 'react';
import CookieConsent from 'react-cookie-consent';
import Cookie from 'common/src/assets/image/cookie.png';
import Image from 'common/src/components/Image';
import Box from 'common/src/components/Box';
import CookiesWrapper from './styles/cookiesBanner.style';

const CookiesBanner = ({
  btnStyle,
  description,
  containerStyle,
  row,
  contentStyle,
}) => {
  return (
    <CookiesWrapper>
      <CookieConsent
        location="bottom"
        buttonText="Zgadzam się"
        cookieName="gatsby-gdpr-google-analytics"
        buttonStyle={btnStyle}
        buttonWrapperClasses="btnWrapper"
        style={containerStyle}
        expires={150}
        contentStyle={contentStyle}
      >
        <Box {...row}>
          <Image
            src={Cookie}
            alt="Client Image"
            style={{ maxHeight: '100%' }}
          />
          <Text
            {...description}
            content="Ta strona korzysta z plików cookies, aby świadczyć usługi na jak
        najwyższym poziomie."
          />
        </Box>
      </CookieConsent>
    </CookiesWrapper>
  );
};

CookiesBanner.propTypes = {
  row: PropTypes.object,
  btnStyle: PropTypes.object,
  containerStyle: PropTypes.object,
};

CookiesBanner.defaultProps = {
  row: {
    flexBox: true,
    ml: ['0px', '15px'],
    mr: ['0px', '15px'],
    alignItems: 'center',
  },
  contentStyle: {
    marginLeft: 0,
    marginRight: 0,
  },
  containerStyle: {
    backgroundColor: 'rgba(238, 238, 238, 0.9)',
    backdropFilter: 'blur(5px)',
    WebkitBackdropFilter: 'blur(5px)',
    paddingLeft: '15px',
    paddingRight: '15px',
    alignItems: 'center',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '0px',
    ml: '10px',
  },
  btnStyle: {
    marginLeft: 0,
    width: '100%',
    fontSize: '14px',
    borderRadius: '3px',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '15px',
    paddingRight: '15px',
    fontWeight: '500',
    color: '#fff',
    fontFamily: 'inherit',
    backgroundColor: '#a70300',
  },
};

export default CookiesBanner;
