import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Image from 'common/src/components/Image';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import FeatureSectionTwoWrapper from './styles/featureSliderTwo.style';

const FeatureSection = ({
  row,
  col,
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  featureTitle,
  iconStyle,
  contentStyle,
  blockWrapperStyle,
  data,
  title,
  heading,
}) => {
  return (
    <FeatureSectionTwoWrapper id="keyfeature">
      <Container>
        <Box {...sectionHeader}>
          <Text {...sectionSubTitle} content={title} />
          <Heading {...sectionTitle} content={heading} />
        </Box>
        <Box className="row" {...row}>
          {data.map((feature, index) => (
            <Box className="col" {...col} key={index}>
              <Fade bottom delay={index * 120}>
                <FeatureBlock
                  icon={
                    <Image
                      src={feature.thumbnail.publicURL}
                      alt={feature.thumbnailAlt}
                    />
                  }
                  wrapperStyle={blockWrapperStyle}
                  iconStyle={iconStyle}
                  contentStyle={contentStyle}
                  title={
                    <Heading
                      content={feature.thumbnailLabel}
                      {...featureTitle}
                    />
                  }
                />
              </Fade>
            </Box>
          ))}
        </Box>
      </Container>
    </FeatureSectionTwoWrapper>
  );
};

// FeatureSection style props
FeatureSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object,
};

// FeatureSection default style
FeatureSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ['56px', '56px'],
  },
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#a70300',
    mb: '10px',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  // feature row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  // feature col default style
  col: {
    width: [1 / 2, 1 / 2, 1 / 3, 1 / 3, 1 / 3],
  },
  // feature block wrapper default style
  blockWrapperStyle: {
    p: ['10px', '20px', '20px', '40px'],
  },
  // feature icon default style
  iconStyle: {
    width: ['80px', '80px', '90px', '100px'],
    height: ['80px', '80px', '90px', '100px'],
    m: '0 auto',
    borderRadius: '50%',
    overflow: 'hidden',
    mb: '15px',
  },
  // feature content default style
  contentStyle: {
    textAlign: 'center',
  },
  // feature title default style
  featureTitle: {
    fontSize: ['16px', '18px'],
    fontWeight: '400',
    color: '#0f2137',
    lineHeight: '1.5',
    mb: '20px',
    letterSpacing: '-0.020em',
  },
  // feature description default style
  featureDescription: {
    fontSize: ['14px', '15px'],
    lineHeight: '1.84',
    color: '#343d48cc',
  },
};

export default () => {
  const data = useStaticQuery(graphql`
    query FeatureSliderTwo {
      markdownRemark(frontmatter: { templateKey: { eq: "FeatureSlider" } }) {
        frontmatter {
          data {
            thumbnail {
              publicURL
            }
            thumbnailLabel
            thumbnailAlt
          }
          title
          heading
        }
      }
    }
  `);

  const { frontmatter } = data.markdownRemark;
  return (
    <FeatureSection
      data={frontmatter.data}
      title={frontmatter.title}
      heading={frontmatter.heading}
    />
  );
};
