import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Card from 'common/src/components/Card';
import Image from 'common/src/components/Image';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import VisitorSectionWrapper, { SectionObject } from './styles/visitor.style';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import ImageBg from 'common/src/assets/image/saas/visitor_bg.png';

const VisitorSectionTemplate = ({
  title,
  description,
  textArea,
  imageWrapper,
  btnStyle,
  titleText,
  contentText,
  buttonText,
  mainImage,
}) => {
  return (
    <VisitorSectionWrapper id="visitorSection">
      <SectionObject>
        <Card className="objectWrapper" {...imageWrapper}>
          <Zoom>
            <Image src={ImageBg} alt="BgImage" />
          </Zoom>
          <Card className="dashboardWrapper" {...imageWrapper}>
            <Fade left>
              <Image
                alt="VisitorDashboard1"
                fluid={mainImage.childImageSharp.fluid}
                imgStyle={{ objectFit: 'contain' }}
              />
            </Fade>
          </Card>
        </Card>
      </SectionObject>
      <Container>
        <Box {...textArea}>
          <FeatureBlock
            title={<Heading content={titleText} {...title} />}
            description={
              <div>
                {contentText.map((text, index) => {
                  return (
                    <Text
                      content={text.description}
                      {...description}
                      key={index}
                    />
                  );
                })}
              </div>
            }
            button={
              <AnchorLink href="#faq_section" offset={70}>
                <Button title={buttonText} {...btnStyle} />
              </AnchorLink>
            }
          />
        </Box>
      </Container>
    </VisitorSectionWrapper>
  );
};

VisitorSectionTemplate.propTypes = {
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object,
};

VisitorSectionTemplate.defaultProps = {
  textArea: {
    width: ['100%', '100%', '45%'],
    ml: [0, 0, '58%'],
  },
  imageWrapper: {
    boxShadow: 'none',
  },
  title: {
    fontSize: ['20px', '26px', '26px', '36px', '48px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.010em',
    mb: '20px',
    maxWidth: ['100%', '100%', '100%', '440px', '440px'],
    lineHeight: '1.5',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '33px',
    maxWidth: ['100%', '100%', '100%', '440px', '440px'],
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
  },
};

export default () => {
  const data = useStaticQuery(graphql`
    query VisitorSection {
      markdownRemark(frontmatter: { templateKey: { eq: "VisitorSection" } }) {
        frontmatter {
          titleText
          texts {
            description
          }
          buttonText
          mainImage {
            childImageSharp {
              fluid(quality: 75) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  `);

  const { frontmatter } = data.markdownRemark;
  return (
    <VisitorSectionTemplate
      titleText={frontmatter.titleText}
      contentText={frontmatter.texts}
      buttonText={frontmatter.buttonText}
      mainImage={frontmatter.mainImage}
    />
  );
};
