import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Container from 'common/src/components/UI/Container';
import FooterWrapper from './styles/footer.style';
import Link from 'common/src/components/Link';
import Logo from 'common/src/components/UIElements/Logo';

const FooterTemplate = ({
  textStyle,
  row,
  title,
  link,
  logoStyle,
  logo,
  copyrightStyle,
}) => {
  return (
    <FooterWrapper>
      <Container className="footer_container">
        <Box className="container" {...row}>
          <Link href={link} target="_blank" rel="noreferrer">
            <Box className="footer_link">
              <Text content={title} {...copyrightStyle} />
              <Logo imageObj={logo} logoStyle={logoStyle} />
            </Box>
          </Link>

          <Text content={'Kontakt: eid@identt.pl'} {...textStyle} />
        </Box>
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
FooterTemplate.propTypes = {
  row: PropTypes.object,
  titleStyle: PropTypes.object,
  link: PropTypes.string,
  title: PropTypes.string,
};

// Footer default style
FooterTemplate.defaultProps = {
  // Footer row
  row: {
    flexBox: true,
    alignItems: 'center',
    justifyContent: 'center',
  },
  // Footer text
  copyrightStyle: {
    color: '#0f2137',
    fontSize: '16px',
    fontWeight: '700',
    textAlign: 'center',
    mr: '5px',
  },
  textStyle: {
    color: '#0f2137',
    fontSize: '16px',
    fontWeight: '700',
    textAlign: 'center',
    mr: ['0px', '5px'],
    ml: ['0px', '20px'],
  },
  logoStyle: {
    width: '100px',
    marginBottom: '15px',
  },
};

export default () => {
  const data = useStaticQuery(graphql`
    query Footer {
      markdownRemark(frontmatter: { templateKey: { eq: "Footer" } }) {
        frontmatter {
          logo {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
            extension
            publicURL
          }
          title
          url
        }
      }
    }
  `);

  const { frontmatter } = data.markdownRemark;
  return (
    <FooterTemplate
      title={frontmatter.title}
      link={frontmatter.url}
      logo={frontmatter.logo}
    />
  );
};
