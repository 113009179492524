import React, { useRef, useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Slide from 'react-reveal/Slide';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Container from 'common/src/components/UI/Container';
import ImageGallery from 'react-image-gallery';
import TimelineSlider from './styles/TimelineSlider.style';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import 'react-image-gallery/styles/css/image-gallery.css';
import {
  TimelineWrapper,
  TimelineItem,
  TimelineIndex,
  TimelineContent,
  TimelineDot,
  Hidden,
} from './styles/timeline.style';
import Image from 'common/src/components/Image';

const TimelineSectionTemplate = ({
  sectionWrapper,
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  row,
  col,
  button,
  textArea,
  imageArea,
  buttonWrapper,
  indexStyle,
  timelineTitle,
  timelineDescription,
  titleText,
  contentText,
  slider,
  buttonText,
}) => {
  const galleryRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    galleryRef.current.slideToIndex(activeSlide);
  }, [activeSlide]);

  return (
    <Box {...sectionWrapper} id="howitworks_section">
      <Container>
        <Box {...sectionHeader}>
          <Text {...sectionSubTitle} content={titleText} />
          <Heading {...sectionTitle} content={contentText} />
        </Box>
        <Box {...row}>
          <Box {...col} {...imageArea}>
            <TimelineSlider>
              <Container>
                <Box className="FeatureSlider">
                  <ImageGallery
                    ref={galleryRef}
                    items={slider.images}
                    className="Slider-img"
                    showPlayButton={false}
                    showFullscreenButton={false}
                    showNav={false}
                    showBullets={false}
                    autoPlay={false}
                    style={{ maxWidth: '60%' }}
                    renderItem={(item) => (
                      <Image
                        style={{ maxHeight: '100%' }}
                        imageObj={item.original}
                        alt={item.originalAlt}
                        className="image-gallery-image"
                      />
                    )}
                  />
                </Box>
              </Container>
            </TimelineSlider>
          </Box>
          <Box {...col} {...textArea}>
            <TimelineWrapper>
              {slider.texts.map((item, index) => (
                <TimelineItem
                  key={`timeline-item-${index}`}
                  className={
                    activeSlide === index ? 'active' : `slide-${index}`
                  }
                  onClick={() => setActiveSlide(index)}
                >
                  <TimelineIndex>
                    <Hidden>
                      <Slide bottom>
                        <Text
                          as="span"
                          content={item.index || `0${index + 1}`}
                          {...indexStyle}
                        />
                      </Slide>
                    </Hidden>
                  </TimelineIndex>
                  <TimelineContent>
                    <Hidden>
                      <Slide bottom delay={100}>
                        <Heading
                          as="h2"
                          content={item.title}
                          {...timelineTitle}
                        />
                      </Slide>
                    </Hidden>
                    <Hidden>
                      <Slide bottom delay={200}>
                        <Text
                          content={item.description}
                          {...timelineDescription}
                        />
                      </Slide>
                    </Hidden>
                  </TimelineContent>
                  <TimelineDot />
                </TimelineItem>
              ))}
            </TimelineWrapper>
          </Box>
        </Box>
        <Box {...buttonWrapper}>
          <AnchorLink href="#contact" offset={70}>
            <Button {...button} title={buttonText} />
          </AnchorLink>
        </Box>
      </Container>
    </Box>
  );
};

TimelineSectionTemplate.propTypes = {
  sectionWrapper: PropTypes.object,
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object,
  imageTwo: PropTypes.object,
  buttonWrapper: PropTypes.object,
  indexStyle: PropTypes.object,
  timelineTitle: PropTypes.object,
  timelineDescription: PropTypes.object,
};

TimelineSectionTemplate.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['60px', '80px', '80px', '80px'],
    pb: ['60px', '80px', '80px', '80px'],
  }, // section header default style
  sectionHeader: {
    mb: '56px',
  },
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#a70300',
    mb: '10px',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '15px',
  },
  textArea: {
    width: ['100%', '100%', '55%', '50%', '55%'],
    mb: ['40px', '40px', '0', '0', '0'],
  },
  imageArea: {
    width: ['100%', '100%', '45%', '50%', '45%'],
    mb: ['30px', '40px', '40px', '0', '0'],
  },
  title: {
    fontSize: ['30px', '38px', '38px', '48px', '48px'],
    fontWeight: '300',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: '15px',
  },
  description: {
    fontSize: '16px',
    color: 'textColor',
    lineHeight: '1.75',
    mb: '33px',
  },
  buttonWrapper: {
    mt: ['25px', '50px'],
    flexBox: true,
    justifyContent: 'center',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    height: '46px',
  },
  imageOne: {
    mb: '40px',
    ml: 'auto',
    mr: 'auto',
  },
  imageTwo: {
    ml: 'auto',
    mr: 'auto',
  },
  indexStyle: {
    fontSize: ['36px', '42px', '52px', '56px', '72px'],
    fontWeight: '300',
    color: '#eaebec',
    display: 'block',
    lineHeight: '1',
    mb: '0',
  },
  timelineTitle: {
    fontSize: ['16px', '17px', '18px', '18px', '20px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '13px',
  },
  timelineDescription: {
    fontSize: ['14px', '15px', '15px', '15px', '16px'],
    lineHeight: '2',
    color: '#5d646d',
    mb: '0',
  },
};

export default () => {
  const data = useStaticQuery(graphql`
    query TimelineSection {
      markdownRemark(frontmatter: { templateKey: { eq: "TimelineSection" } }) {
        frontmatter {
          titleText
          contentText
          buttonText
          slider {
            texts {
              title
              description
            }
            images {
              original {
                childImageSharp {
                  fluid(quality: 75) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
                extension
                publicURL
              }
              originalAlt
            }
          }
        }
      }
    }
  `);

  const { frontmatter } = data.markdownRemark;
  return (
    <TimelineSectionTemplate
      titleText={frontmatter.titleText}
      contentText={frontmatter.contentText}
      slider={frontmatter.slider}
      buttonText={frontmatter.buttonText}
    />
  );
};
