import styled from 'styled-components';
import Mockup from 'common/src/assets/image/app/iphone-mockup.png';

const FeatureSliderWrapper = styled.div`
  .FeatureSlider {
    padding-top: 30px;
    .image-gallery-slide-wrapper {
      width: 360px;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      position: relative;

      padding: 0px 20px;
      border-radius: 20px;

      &::before {
        content: '';
        background-image: url(${Mockup});
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        border-radius: 50px;
      }
      &:after {
        content: '';
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: 0 0 68px rgba(0, 0, 0, 1);
        display: block;
        position: absolute;
        border-radius: 40px;
      }
      .image-gallery-image {
        margin: 20px 0px;
      }
    }

    .image-gallery-thumbnail {
      display: none;
    }
  }
`;
export default FeatureSliderWrapper;
