import styled from 'styled-components';

const ListWrapper = styled.ul``;
const Item = styled.li`
  position: relative;
  &:before {
    content: '-';
    display: inline-block;
    position: absolute;
    margin-left: -1em;
    transform: translateY(2px);
  }
`;

export { ListWrapper, Item };
