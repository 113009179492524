import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import FeatureSectionWrapper from './styles/featureSection.style';
import Image from 'common/src/components/Image';

const FeatureSectionTemplate = ({
  row,
  col,
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  featureTitle,
  featureDescription,
  iconStyle,
  contentStyle,
  blockWrapperStyle,
  features,
  title,
  heading,
}) => {
  return (
    <FeatureSectionWrapper id="oursolution_section">
      <Container>
        <Box {...sectionHeader}>
          <Text content={title} {...sectionSubTitle} />
          <Heading content={heading} {...sectionTitle} />
        </Box>
        <Box className="row" {...row}>
          {features.map((feature, index) => (
            <Box className="col" {...col} key={index}>
              <FeatureBlock
                icon={<Image imageObj={feature.image} alt="Demo Image" />}
                wrapperStyle={blockWrapperStyle}
                iconStyle={iconStyle}
                contentStyle={contentStyle}
                title={
                  <Heading
                    content={feature.title}
                    {...featureTitle}
                    style={{ textAlign: 'center', paddingTop: 20 }}
                  />
                }
                description={
                  <Text
                    content={feature.description}
                    {...featureDescription}
                    style={{ textAlign: 'center' }}
                  />
                }
                className="saasFeature"
              />
            </Box>
          ))}
        </Box>
      </Container>
    </FeatureSectionWrapper>
  );
};

FeatureSectionTemplate.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object,
};

FeatureSectionTemplate.defaultProps = {
  sectionHeader: {
    mb: ['40px', '40px', '40px', '80px'],
  },
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#a70300',
    mb: '10px',
  },
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  col: {
    width: [1, 1 / 2, 1 / 3, 1 / 3],
  },
  blockWrapperStyle: {
    p: ['30px', '20px', '20px', '20px'],
  },
  // feature icon default style
  iconStyle: {
    width: ['70px', '75px', '75px', '84px'],
    height: ['70px', '75px', '75px', '84px'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: ['32px', '36px'],
    color: '#ffffff',
    overflow: 'hidden',
    mb: ['20px', '20px', '20px', '30px'],
    borderBottomLeftRadius: '50%',
  },
  // feature content default style
  contentStyle: {
    textAlign: 'left',
  },
  // feature title default style
  featureTitle: {
    fontSize: ['18px', '20px'],
    fontWeight: '400',
    color: '#0f2137',
    lineHeight: '1.5',
    mb: ['10px', '10px', '10px', '20px'],
    letterSpacing: '-0.020em',
  },
  // feature description default style
  featureDescription: {
    fontSize: '15px',
    lineHeight: '1.75',
    color: '#343d4ccc',
  },
};

export default () => {
  const data = useStaticQuery(graphql`
    query FeatureSection {
      markdownRemark(frontmatter: { templateKey: { eq: "FeatureSection" } }) {
        frontmatter {
          title
          heading
          features {
            image {
              childImageSharp {
                fluid(maxWidth: 70) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
              extension
              publicURL
            }
            title
            description
          }
        }
      }
    }
  `);

  const { frontmatter } = data.markdownRemark;
  return (
    <FeatureSectionTemplate
      title={frontmatter.title}
      heading={frontmatter.heading}
      features={frontmatter.features}
    />
  );
};
