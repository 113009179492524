import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Container from 'common/src/components/UI/ContainerTwo';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Input from 'common/src/components/Input';
import Text from 'common/src/components/Text';
import SectionWrapper, {
  FooterInner,
  Content,
  SubscriptionForm,
} from './styles/ContactForm.style';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Za krótkie imię!')
    .max(100, 'Za długie imię!')
    .required('To pole jest wymagane'),
  email: Yup.string()
    .email('Niepoprawny adres email')
    .required('To pole jest wymagane'),
  message: Yup.string()
    .min(2, 'Za krótka wiadomość!')
    .required('To pole jest wymagane'),
});

const ContactFormTemplate = ({
  btnStyle,
  heading,
  contentText,
  failureInfo,
  emailPlaceholder,
  namePlaceholder,
  messagePlaceholder,
  sendButtonText,
}) => {
  const [submittedStatus, setSubmittedStatus] = useState('');
  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&');
  }

  const handleSubmit = (values, resetForm) => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact-form', ...values }),
    })
      .then(() => (window.location.href = '/thank-you'))
      .catch(() => setSubmittedStatus('failure'))
      .finally(() => resetForm());
  };

  return (
    <SectionWrapper id="contact">
      <Container>
        <FooterInner>
          <Content>
            <Heading as="h3" content={heading} />
            <Text content={contentText} />
          </Content>
          <SubscriptionForm>
            {submittedStatus === 'failure' && (
              <Text
                fontWeight="bold"
                as="h2"
                content={failureInfo}
                style={{ color: '#d84329' }}
              />
            )}
            <div>
              <Formik
                initialValues={{ name: '', email: '', message: '' }}
                validationSchema={ContactSchema}
                onSubmit={(values, actions) =>
                  handleSubmit(values, actions.resetForm)
                }
              >
                {({ isValid, isSubmitting }) => (
                  <Form
                    name="contact-form"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                  >
                    <Field type="hidden" name="form-name" />
                    <Field type="hidden" name="bot-field" />
                    <Input
                      formikField={
                        <Field
                          name="name"
                          type="text"
                          placeholder={namePlaceholder}
                        />
                      }
                    />
                    <ErrorMessage name="name" />
                    <Input
                      formikField={
                        <Field
                          name="email"
                          type="email"
                          placeholder={emailPlaceholder}
                        />
                      }
                    />
                    <ErrorMessage name="email" />
                    <Input
                      formikField={
                        <Field
                          name="message"
                          component="textarea"
                          placeholder={messagePlaceholder}
                          maxLength={1000}
                          style={{ fontFamily: 'Roboto', resize: 'vertical' }}
                        />
                      }
                    />
                    <ErrorMessage name="message" />
                    <Button
                      title={sendButtonText}
                      disabled={!isValid || isSubmitting}
                      {...btnStyle}
                      type="submit"
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </SubscriptionForm>
        </FooterInner>
      </Container>
    </SectionWrapper>
  );
};

ContactFormTemplate.defaultProps = {
  textArea: {
    width: ['100%', '100%', '45%'],
    ml: [0, 0, '58%'],
  },
  title: {
    fontSize: ['20px', '26px', '26px', '36px', '48px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.010em',
    mb: '20px',
    maxWidth: ['100%', '100%', '100%', '440px', '440px'],
    lineHeight: '1.5',
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    colors: 'primaryWithBg',
  },
};

export default () => {
  const data = useStaticQuery(graphql`
    query ContactForm {
      markdownRemark(frontmatter: { templateKey: { eq: "ContactForm" } }) {
        frontmatter {
          heading
          contentText
          successInfo
          failureInfo
          emailPlaceholder
          namePlaceholder
          messagePlaceholder
          sendButtonText
        }
      }
    }
  `);

  const { frontmatter } = data.markdownRemark;
  return (
    <ContactFormTemplate
      heading={frontmatter.heading}
      contentText={frontmatter.contentText}
      successInfo={frontmatter.successInfo}
      failureInfo={frontmatter.failureInfo}
      emailPlaceholder={frontmatter.emailPlaceholder}
      namePlaceholder={frontmatter.namePlaceholder}
      messagePlaceholder={frontmatter.messagePlaceholder}
      sendButtonText={frontmatter.sendButtonText}
      {...frontmatter}
    />
  );
};
