import styled from 'styled-components';
import FooterImage from 'common/src/assets/image/hosting/footer-bg.png';

const FooterWrapper = styled.footer`
  position: relative;
  background-color: #f9fbfd;
  overflow: hidden;
  background: linear-gradient(to bottom, #f7f8fd 0%, #fafbff 100%);

  @media (min-width: 576px) {
    padding-top: 130px;
    &:before {
      content: '';
      position: absolute;
      width: 104%;
      padding-bottom: 104%;
      border-top-right-radius: 11%;
      top: 14%;
      left: 0;
      pointer-events: none;
      background-color: #fff;
      transform: rotate(-6deg);
      @media (max-width: 767px) {
        padding-bottom: 150%;
      }
    }
  }

  .footer_container {
    background-repeat: no-repeat;
    background-position: center 50px;
    padding-top: 80px;
    padding-bottom: 20px;
    position: relative;
    @media (min-width: 576px) {
      background-image: url(${FooterImage});
    }
    @media (max-width: 767px) {
      padding: 0;
    }
  }
  .container {
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
  .footer_link {
    display: flex;
    align-items: center;
  }
`;

export default FooterWrapper;
