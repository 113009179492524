import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../Text';
import Image from '../../Image';

const Logo = ({
  logoWrapperStyle,
  logoStyle,
  titleStyle,
  logoSrc,
  imageObj,
  title,
  ...props
}) =>
  logoSrc || imageObj ? (
    <Image src={logoSrc} imageObj={imageObj} alt={title} style={logoStyle} />
  ) : (
    <Text content={title} {...titleStyle} />
  );

Logo.propTypes = {
  logoSrc: PropTypes.string,
  title: PropTypes.string.isRequired,
  logoWrapperStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  titleStyle: PropTypes.object,
};

Logo.defaultProps = {
  title: 'Logo',
  logoWrapperStyle: {
    display: 'inline-block',
    mr: '1rem',
    'a:hover,a:focus': {
      textDecoration: 'none',
    },
  },
  titleStyle: {
    display: 'inline-block',
    fontSize: '2rem',
    lineHeight: 'inherit',
    whiteSpace: 'nowrap',
  },
};
export default Logo;
