/* eslint-disable */
import React, { Fragment } from 'react';
import { Item, ListWrapper } from './styles/unorderedList.style';
import Text from './Text';

const List = ({ textStyle, list }) => (
  <ListWrapper>
    {list.map((item, index) => {
      return (
        <Item key={index}>
          <Text content={item.text} {...textStyle} />
        </Item>
      );
    })}
  </ListWrapper>
);

export default List;
