import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Image from 'common/src/components/Image';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import Particles from './Particle';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import BannerWrapper, {
  DiscountLabel,
  BannerObject,
} from './styles/bannerSection.style';

const BannerSectionTemplate = ({
  row,
  col,
  title,
  btnStyle,
  description,
  discountText,
  discountAmount,
  outlineBtnStyle,
  primaryBtnText,
  secondaryBtnText,
  bannerImage,
  featuredColorText,
  featuredNormalText,
  headingTitle,
  headingContent,
}) => {
  const ButtonGroup = () => (
    <Fragment>
      <AnchorLink href="#howitworks_section" offset={70}>
        <Button title={primaryBtnText} {...btnStyle} />
      </AnchorLink>
      <AnchorLink href="#faq_section" offset={70}>
        <Button
          className="outlined"
          title={secondaryBtnText}
          variant="outlined"
          {...outlineBtnStyle}
        />
      </AnchorLink>
    </Fragment>
  );
  return (
    <BannerWrapper id="banner_section">
      <Particles />
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <DiscountLabel>
              <Text content={featuredColorText} {...discountAmount} />
              <Text content={featuredNormalText} {...discountText} />
            </DiscountLabel>
            <FeatureBlock
              title={<Heading content={headingTitle} {...title} />}
              description={<Text content={headingContent} {...description} />}
              button={<ButtonGroup />}
            />
          </Box>
        </Box>
      </Container>
      <BannerObject>
        <div className="objectWrapper">
          <Image
            imageObj={bannerImage}
            alt="Client Image"
            style={{ maxHeight: '100%' }}
            fadeIn={true}
          />
        </div>
      </BannerObject>
    </BannerWrapper>
  );
};

BannerSectionTemplate.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

BannerSectionTemplate.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, '70%', '50%', '45%'],
  },
  title: {
    fontSize: ['22px', '34px', '30px', '55px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.5',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '0',
  },
  btnStyle: {
    minWidth: ['120px', '120px', '120px', '156px'],
    fontSize: ['13px', '14px'],
    fontWeight: '500',
    colors: 'primaryWithBg',
  },
  outlineBtnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#a70300',
    ml: '18px',
  },
  discountAmount: {
    fontSize: '14px',
    color: '#a70300',
    mb: 0,
    as: 'span',
    mr: '0.4em',
    fontWeight: 700,
  },
  discountText: {
    fontSize: ['13px', '14px'],
    color: '#0f2137',
    mb: 0,
    as: 'span',
    fontWeight: 500,
  },
};

export default () => {
  const data = useStaticQuery(graphql`
    query BannerSection {
      markdownRemark(frontmatter: { templateKey: { eq: "BannerSection" } }) {
        frontmatter {
          primaryBtnText
          secondaryBtnText
          bannerImage {
            childImageSharp {
              fluid(quality: 75) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
            extension
            publicURL
          }
          featuredColorText
          featuredNormalText
          headingTitle
          headingContent
        }
      }
    }
  `);

  const { frontmatter } = data.markdownRemark;
  return (
    <BannerSectionTemplate
      primaryBtnText={frontmatter.primaryBtnText}
      secondaryBtnText={frontmatter.secondaryBtnText}
      bannerImage={frontmatter.bannerImage}
      featuredColorText={frontmatter.featuredColorText}
      featuredNormalText={frontmatter.featuredNormalText}
      headingTitle={frontmatter.headingTitle}
      headingContent={frontmatter.headingContent}
    />
  );
};
