import React, { Fragment, useEffect } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { saasTheme } from 'common/src/theme/saas';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/styles/saas.style';
import Navbar from '../containers/Navbar';
import BannerSection from '../containers/BannerSection';
import FeatureSection from '../containers/FeatureSection';
import VisitorSection from '../containers/VisitorSection';
import FaqSection from '../containers/FaqSection';
import Footer from '../containers/Footer';
import TimelineSection from '../containers/TimelineSection';
import FeatureSliderTwo from '../containers/FeatureSliderTwo';
import ContactForm from '../containers/ContactForm';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import SEO from '../components/seo';
import CookiesBanner from '../containers/CookiesBanner';
//import BlogSection from '../containers/BlogSection';

export default ({ location }) => {
  useEffect(() => {
    if (location.state?.scrollTo)
      document.getElementById(location.state.scrollTo).scrollIntoView();
  }, []);

  return (
    <ThemeProvider theme={saasTheme}>
      <Fragment>
        <SEO title="eID | mobilne SDK do potwierdzania tożsamości" />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <BannerSection />
          <TimelineSection />
          <FeatureSection />
          <VisitorSection />
          <FeatureSliderTwo />
          {/*<BlogSection />*/}
          <FaqSection />
          <ContactForm />
          <Footer />
          <CookiesBanner />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
